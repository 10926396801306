.catalog-table-wrapper {
  --tab-header-height: 120px;

  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}

.catalog-table {
  --expand-button-size: 32px;
  --catalog-table-column-gap: var(--space-s);
  --catalog-table-row-min-height: 36px;
  --mat-tree-node-min-height: var(--catalog-table-row-min-height);
  --catalog-row-active-background-color: color-mix(
    in srgb,
    transparent,
    var(--primary-color) 8%
  );
}

.catalog-table__body {
  transition: opacity 100ms ease-in-out;
}

.catalog-table__body_loading {
  opacity: 0.65;
}

.catalog-table .catalog-table__row {
  display: grid;
  align-items: center;
  grid-template-columns: var(--catalog-table-row-columns);
  min-height: var(--catalog-table-row-min-height);
  background-color: var(
    --catalog-row-background-color,
    var(--background-color),
  );
  width: 100%;
  min-width: min-content;
  column-gap: var(--catalog-table-column-gap);
  padding: var(--space-xxs) 0;
  padding-left: var(--space-s);
  padding-right: var(--space-s);
}

.catalog-table .catalog-table__row_selected {
  --catalog-row-background-color: var(--catalog-row-active-background-color);

  background-color: var(--catalog-row-active-background-color);
}

.catalog-table .catalog-table__row_selected.dynamic-background {
  --second-color: var(--catalog-row-active-background-color);
}

.catalog-table .catalog-table__row_top {
  position: sticky;
  top: 0;
  color: var(--font-accent-light-color);
  border-bottom: 1px solid var(--border-color);
  background-color: var(--background-color);

  /* Needs because mat-icon have z-index 0 */
  z-index: 1;
}

.catalog-table__row:not(.catalog-table__row_top) {
  border-bottom: 1px solid var(--border-faint-color);
}

.catalog-expand-container {
  display: flex;
  align-items: center;
  gap: var(--space-s);
}

.catalog-table .catalog-expand-container__button {
  width: var(--expand-button-size);
  height: var(--expand-button-size);
}

.catalog-expand-container__icon {
  color: var(--font-accent-light-color);
}

.catalog-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--space-m);
}

.catalog-section__selected-items-chip {
  position: absolute;
  bottom: var(--space-l);
  z-index: 2;
}
