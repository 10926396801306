:root {
  --font-size: 14px;
  --font-family: heebo, roboto, -apple-system, blinkmacsystemfont, "Segoe UI",
    oxygen, ubuntu, cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --background-color: white;
  --background-contrast-color: #222222;
  --background-light-contrast-color: #dadada;
  --background-faint-color: #7f7f7f;
  --background-light-faint-color: #f1efef;
  --background-accent-color: #eaedef;
  --background-light-accent-color: #f9f9f9;
  --background-accent-highlight-color: #f9efcd;
  --surface-background-color: #f2f2f2;
  --surface-dark-background-color: #2c2c2c;

  /* Brand colors */

  /* Primary. */
  --primary-color: #136392;
  --primary-background-color: #115b8a;
  --primary-dark-color: #0d486e;
  --primary-light-color: #e3ecf2;
  --primary-contrast-color: white;
  --primary-dark-contrast-color: white;
  --primary-light-contrast-color: black;

  /* Accent. */
  --accent-color: #eaedef;
  --accent-dark-color: #383b3d;
  --accent-light-color: #e1e7eb;
  --accent-contrast-color: #383b3d;
  --accent-dark-contrast-color: white;
  --accent-light-contrast-color: #383b3d;
  --accent-highlight-color: #deae07;

  /* Warn. */
  --warn-color: #ff1a40;
  --warn-dark-color: #aa190f;
  --warn-light-color: #e4746c;
  --warn-contrast-color: white;
  --warn-dark-contrast-color: white;
  --warn-light-contrast-color: black;

  /* Other. */
  --info-color: #0c927c;
  --info-background-color: #d2f8f2;
  --faint-color: #7f7f7f;
  --faint-light-color: #f1efef;
  --radius: 4px;
  --backdrop-color: rgb(0 0 0 / 0.3);
  --border-color: #d0d3d6;
  --border-light-color: rgb(0 0 0 / 0.25);
  --border-faint-color: #dadde0;
  --border-dark-color: #b3b6b9;
  --table-row-hover-color: #efefef;
  --highlight-block-color: #f4f5f5;

  /* Font colors. */
  --font-primary-color: rgb(0 0 0);
  --font-primary-light-color: rgb(0 0 0 / 0.65);
  --font-contrast-color: rgb(255 255 255);
  --font-accent-color: rgb(18 18 18);
  --font-accent-light-color: rgb(18 18 18 / 0.65);
  --font-primary-disabled-color: rgb(0 0 0 / 0.38);

  /* Project status colors */
  --in-progress-color: #367aa2;
  --in-review-color: var(--accent-highlight-color);
  --ready-color: var(--accent-highlight-color);
  --submitted-color: var(--accent-highlight-color);
  --accepted-color: #0c917a;
  --rejected-color: #c43b06;
  --cancelled-color: #c43b06;
  --on-hold-color: #7d8184;

  /** Table. */
  --table-border: rgb(0 0 0 / 0.2);
  --table-header-color: #5e6770;
  --table-header-background-color: #5e6770;
  --table-footer-background-color: #eaedef;
  --table-footer-background-dark-color: #d0d2d6;
  --table-footer-text-color: #575858;
  --table-header-light-color: #979797;
  --table-header-border-color: #979797;
  --table-header-font-weight: 500;
  --table-footer-font-weight: 500;
  --table-cell-border-color: #e2e5e8;
  --table-footer-cell-border-color: #dedede;
  --table-cell-border-dark-color: #b3b6b9;
  --table-row-highlight-color: #e3ecf2;
  --table-row-highlight-middle-color: #b8d0de;
  --table-row-highlight-semi-dark-color: #89b1c9;
  --table-row-highlight-dark-color: #367aa2;
  --table-row-highlight-transparent-color: rgb(227 236 242 / 0.5);
  --table-row-highlight-hover-color: #dbe4ea;
  --table-expanded-cell-color: #7c8086;
  --table-group-row-color: #7c8086;
  --table-group-border-color: #979797;
  --table-nested-group-border-color: #97979759;
  --table-font-color: #000000;
  --table-cell-placeholder-color: rgb(0 0 0 / 0.4);
  --table-disabled-cell-background-color: #eceeee;
  --table-disabled-cell-border-color: #dadde0;
  --table-disabled-cell-font-color: #8a8a8a;
  --table-custom-cell-background-color: #f4f5f5;
  --table-custom-cell-font-color: #444444;
  --sticky-column-border-color: #818181;
  --table-editable-cell-border: #a4a4a4;
  --table-fixed-row-border-color: #818181;
  --table-fixed-column-border-color: #6c6c6c;

  /** File table. */
  --file-table-header-color: #6a6a6a;
  --file-icon: rgb(0 0 0 / 0.5);

  /** Editable element. */
  --editable-element-background: #fafafa;

  /** Form control colors. */
  --control-select-background-color: #dadde0;
  --control-disabled-background-color: rgb(0 0 0 / 0.1);
  --control-border-color: rgb(0 0 0 / 0.4);
  --control-reset-available-color: rgb(222 174 7 / 0.1);

  /* Opacity variables. */
  --opacity-light-transparency: 0.7;
  --opacity-medium-transparency: 0.5;
  --opacity-strong-transparency: 0.25;

  /** Alert colors. */
  --alert-warn-color: rgba(222 174 7 / 0.15);

  /** Systems module. */
  --component-nested-space: 25px;

  /** Search colors. */
  --search-matched-color: #ffff00;
  --search-active-color: #ffa500;

  /** Service rows colors. */
  --service-sub-category-row-color: var(--background-accent-color);
  --service-row-color: var(--primary-color);

  /** Drop zone color.  */
  --drop-zone-color: rgb(255 255 255 / 0.6);

  /* Breadcrumb colors. */
  --breadcrumb-item-color: rgb(0 0 0 / 0.6);
  --breadcrumb-trailing-item-color: rgb(0 0 0 / 0.9);

  /* Catalog */
  --catalog-chip-background-color: #5e6770;
  --catalog-chip-color: #ffffff;
}
